@mixin headline($tag : 'h2') {

  display: block;
  color: var(--color-headline);
  @include headline-letter-spacing($tag);
  @include fw-extra-bold;
  @include fs;

  @if $tag == 'h1' {
    font-size: 22px;
    font-size: calculateRem(22);
    line-height: 24px;
    line-height: calculateRem(24);
    @media screen and (min-width: #{$bp-414}) {
      font-size: 24px;
      font-size: calculateRem(24);
      line-height: 28px;
      line-height: calculateRem(28);
    }
   @media screen and (min-width: #{$bp-1670}) {
      font-size: 60px;
      font-size: calculateRem(60);
      line-height: 70px;
      line-height: calculateRem(70);
    }
    @media screen and (min-width: #{$bp-1920}) {
      font-size: 80px;
      font-size: calculateRem(80);
      line-height: 90px;
      line-height: calculateRem(90);
    }
  }

  @if $tag == 'h2' {
    font-size: 20px;
    font-size: calculateRem(20);
    line-height: 24px;
    line-height: calculateRem(24);
    @media screen and (min-width: #{$bp-414}) {
      font-size: 24px;
      font-size: calculateRem(24);
      line-height: 28px;
      line-height: calculateRem(28);
    }
   @media screen and (min-width: #{$bp-1670}) {
      font-size: 36px;
      font-size: calculateRem(36);
      line-height: 40px;
      line-height: calculateRem(40);
    }
    @media screen and (min-width: #{$bp-1920}) {
      font-size: 50px;
      font-size: calculateRem(50);
      line-height: 54px;
      line-height: calculateRem(54);
    }
  }

  @if $tag == 'h3' {
    font-size: 18px;
    font-size: calculateRem(18);
    line-height: 22px;
    line-height: calculateRem(22);
    @media screen and (min-width: #{$bp-414}) {
      font-size: 22px;
      font-size: calculateRem(22);
      line-height: 28px;
      line-height: calculateRem(28);
    }
   @media screen and (min-width: #{$bp-1670}) {
      font-size: 30px;
      font-size: calculateRem(30);
      line-height: 34px;
      line-height: calculateRem(34);
    }
    @media screen and (min-width: #{$bp-1920}) {
      font-size: 36px;
      font-size: calculateRem(36);
      line-height: 40px;
      line-height: calculateRem(40);
    }
  }

  @if $tag == 'h4' {
    font-size: 18px;
    font-size: calculateRem(18);
    line-height: 22px;
    line-height: calculateRem(22);
    @media screen and (min-width: #{$bp-414}) {
      font-size: 20px;
      font-size: calculateRem(20);
      line-height: 30px;
      line-height: calculateRem(30);
    }
   @media screen and (min-width: #{$bp-1670}) {
      font-size: 26px;
      font-size: calculateRem(26);
      line-height: 32px;
      line-height: calculateRem(32);
    }
    @media screen and (min-width: #{$bp-1920}) {
      font-size: 32px;
      font-size: calculateRem(32);
      line-height: 36px;
      line-height: calculateRem(36);
    }
  }

}

@mixin headline-letter-spacing($tag : 'h2') {
  letter-spacing: var(--letter-spacint-headline);

  @if $tag == 'h2' {
    --letter-spacint-headline: 2.4px;
   @media screen and (min-width: #{$bp-1670}) {
      --letter-spacint-headline: 3.6px;
    }
    @media screen and (min-width: #{$bp-1920}) {
      --letter-spacint-headline: 5px;
    }
  }

  @if $tag == 'h3' {
    --letter-spacint-headline: 2.4px;
   @media screen and (min-width: #{$bp-1670}) {
      --letter-spacint-headline: 3.6px;
    }
    @media screen and (min-width: #{$bp-1920}) {
      --letter-spacint-headline: 5px;
    }
  }

  @if $tag == 'h4' {
    letter-spacing: normal;
  }

  @if $tag == 'overline' {
    --letter-spacint-headline: 1px;
   @media screen and (min-width: #{$bp-1670}) {
      --letter-spacint-headline: 1px;
    }
    @media screen and (min-width: #{$bp-1920}) {
      --letter-spacint-headline: 1.2px;
    }
  }
}

@mixin overline-text-definition() {
  @include fw-bold;
  @include headline-letter-spacing('overline');
  color: var(--color-headline);
  text-transform: uppercase;

  font-size: 14px;
  font-size: calculateRem(14);
  line-height: 19px;
  line-height: calculateRem(19);
 @media screen and (min-width: #{$bp-1670}) {
    font-size: 14px;
    font-size: calculateRem(14);
    line-height: 19px;
    line-height: calculateRem(19);
  }
  @media screen and (min-width: #{$bp-1920}) {
    font-size: 16px;
    font-size: calculateRem(16);
    line-height: 21px;
    line-height: calculateRem(21);
  }

}

@mixin table-headline() {
  height: var(--header-height);
  color: var(--color-text, #ffffff);
  @include fw-bold;
  @include fs;
  padding: $gutter;
  vertical-align: middle;
  cursor: default;
  a {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    color: var(--color-text);
    text-transform: none;
    @include can_hover {
      &:hover {
        color: var(--color-link);
      }
    }
  }
}

.frame-header {
  width: 100%;
  hyphens: auto;
  word-break: break-word;

  @media screen and (min-width: #{$bp-1366}) {
    hyphens: initial;
  }

  + * {
    margin-top: 20px;
   @media screen and (min-width: #{$bp-1366}) {
      margin-top: 25px;
    }
    @media screen and (min-width: #{$bp-1920}) {
      margin-top: 30px;
    }
  }

  p {
    padding-bottom: 0;
  }
}

.frame-overline {
  position: relative;
  display: inline-block;
  @include overline-text-definition;
  padding-left: 45px;
  margin-bottom: 12px;
 @media screen and (min-width: #{$bp-1366}) {
    margin-bottom: 19px;
  }
  @media screen and (min-width: #{$bp-1920}) {
    margin-bottom: 30px;
  }

  a {
    @include overline-text-definition;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: var(--overline-line-height);
    background: var(--color-primary);
  }
}

.frame-headline {
  text-transform: uppercase;

  span {
    display: block;

    &.color--primary {
      @include fw-extra-bold;
    }

    &.color--text-color {
      @include fw-light;
    }
  }
}

.hamburger {

  position: relative;
  z-index: $z-1;

  height: var(--header-height);
  width: var(--hamburger-width);
  @include border-styling('left');

  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity 0.15s linear, filter 0.15s linear, background 0.35s ease;

  padding: 0 15px;
  background: var(--color-sidebar-background);

  @media screen and (min-width: #{$bp-mobileNav}) {
    padding: 0 $gutter;
  }

  @media screen and (min-width: #{$bp-mobileNav}) {
    display: none;
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 11px;
    z-index: 5;
    @media screen and (min-width: #{$bp-mobileNav}) {
      width: 58px;
    }
  }

  &__inner {
    display: block;
    position: relative;

    width: 100%;
    height: var(--hamburger-line-height);
    background: var(--color-primary);

    transition: transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), background 0.35s ease;

    &:before, &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: var(--hamburger-line-height);
      background: var(--color-primary);
    }

    &:before {
      top: -6px;
      transition: top 0.14s ease, opacity 0.1s ease, background 0.35s ease;

      @media screen and (min-width: #{$bp-mobileNav}) {
        top: -8px;
      }
    }

    &:after {
      top: 6px;
      transition: bottom 0.14s ease, opacity 0.1s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), background 0.35s ease;
      @media screen and (min-width: #{$bp-mobileNav}) {
        top: 8px;
      }
    }

  }

}

// hover
.hamburger:hover {
  background: var(--color-primary);
  color: var(--color-white);
  .hamburger__inner {
    background: var(--color-white);
    &:before, &:after {
      background: var(--color-white);
    }
  }
}

// open
.hamburger.is-open {
  .hamburger__inner {
    transform: rotate(45deg);
    transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.35s ease;

    &:before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease, opacity 0.14s ease, background 0.35s ease;
    }

    &:after {
      top: 0;
      transform: rotate(-90deg);
      transition: bottom 0.1s ease, opacity 0.14s ease, transform 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.35s ease;
    }
  }
}


.page-content__main {
  min-height: calc(100vh - var(--header-height) - 179px);
  @media screen and (max-width: #{$bp-428}) {
    overflow: hidden;
    * {
      hyphens: auto;
    }
  }
  @media screen and (min-width: #{$bp-768}) {
    min-height: calc(100vh - var(--header-height) - 164px);
  }
  @media screen and (min-width: #{$bp-1024}) {
    min-height: calc(100vh - var(--header-height) - 116px);
  }
  @media screen and (min-width: #{$bp-mobileNav}) {
    min-height: calc(100vh - var(--header-height) - 115px);
  }
  @media screen and (min-width: #{$bp-1366}) {
    min-height: auto;
  }
}

.page-content__hero {
  &:empty {
    height: var(--header-height);
  }
}

.page-content__aside {

  display: flex;
  flex-direction: column;

  @media screen and (min-width: #{$bp-mobileNav}) {
    display: flex;
    position: fixed;
    z-index: $z-menu;
    right: 0;
    left: auto;
    top: var(--header-height);
    width: var(--hamburger-width);
    height: calc(100vh - var(--header-height));
    justify-content: flex-end;
    @include border-styling('left');
  }

  & > a, & > div {
    @media screen and (min-width: #{$bp-mobileNav}) {
      display: block;
      height: var(--header-height);
      width: 100%;
      border-left: none;
      border-right: none;
    }
  }

  .page-contact {
    @media screen and (min-width: #{$bp-mobileNav}) {
      order: 3;
    }
  }

  .language-changer {
    z-index: 5;
    @media screen and (min-width: #{$bp-mobileNav}) {
      order: 2;
    }
  }

  .nav-list {
    @media screen and (min-width: #{$bp-mobileNav}) {
      order: 1;
    }
  }

  .nav-list__title-wrapper, .nav-list__link {
    background: transparent;
  }

}

// =========
// z-index
// =========
$z-0: 0;
$z-1: 1;
$z-5: 1;
$z-menu: 990;
$z-above-menu: 995;
$z-sticky-element: 900;

// =========
// fonts > font-family
// =========
$font-bold: 'Barlow Bold', verdana, arial, helvetica, sans-serif;
$font-extra-bold: 'Barlow ExtraBold', verdana, arial, helvetica, sans-serif;
$font-medium: 'Barlow Medium', verdana, arial, helvetica, sans-serif;
$font-light: 'Barlow Light', verdana, arial, helvetica, sans-serif;

$font-bold-italic: 'Barlow Bold Italic', verdana, arial, helvetica, sans-serif;
$font-extra-bold-italic: 'Barlow ExtraBold Italic', verdana, arial, helvetica, sans-serif;
$font-medium-italic: 'Barlow Medium Italic', verdana, arial, helvetica, sans-serif;
$font-light-italic: 'Barlow Light Italic', verdana, arial, helvetica, sans-serif;

// =========
// fonts > font-sizes
// =========
$font-base: 16;

// =========
// Grid
// =========
$gutter: 18px;
$space: 55px;
$slider-arrow-size: 37px;
$width-20vw: 20vw;
$width-1-of-12: 7.14vw;

/**
** Breakpoints
**/
// min or max
$media-direction: min; // min / max — default is min (mobile first)

// =========
// breakpoints
// =========

$bp-mobileNav: 1024px;
$bp-gridColumnChange: 1024px;
$bp-navigation4Col: 1640px;
$bp-downloadTableIsGrid: 1600px;
$bp-basicTable: 768px;

$bp-414: 414px;
$bp-428: 428px;
$bp-520: 520px;
$bp-768: 768px;
$bp-850: 850px;
$bp-1024: 1024px;
$bp-1280: 1280px;
$bp-1281: 1281px;
$bp-1366: 1366px;
$bp-1420: 1420px;
$bp-1542: 1542px;
$bp-1670: 1670px;
$bp-1920: 1920px;

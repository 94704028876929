.icon--chevron-up-small {
  width: 15.95px;
  height: auto;
}

.icon--logo {
  width: calc(#{$space});
  height: auto;
  @media screen and (min-width: #{$bp-mobileNav}) {
    width: calc(#{$space} * 2);
    height: auto;
  }
 @media screen and (min-width: #{$bp-1366}) {
    width: 130px;
  }
}

.icon--logo-mobile {
  width: 29.416px;
  height: 29.644px;
}

.icon--slider-arrow-right,
.icon--slider-arrow-left {
  width: $slider-arrow-size;
  height: calc(#{$slider-arrow-size} + 1px);
  color: var(--color-link);
}

.icon--scroll-top-btn {
  width: calc(var(--content-space-left) - 2 * #{$gutter});
  height: auto;
  color: var(--color-primary);
  transition: 0.25s ease transform;
  @media screen and (min-width: #{$bp-mobileNav}) {
    width: 61px;
  }
  @include can_hover {
    &:hover {
      transform: translateY(-6px);
    }
  }
}


.icon--download-arrow {
  width: 37px;
  height: 37px;
}

.ce-uploads--default {
  .icon--download-arrow {
    width: 37px;
    height: 37px;
  }
}

.ce-uploads--cols-2 {
  .icon--download-arrow {
    svg {
      width: 21px;
      height: 21px;
    }
  }
}

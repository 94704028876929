.header-navigation__wrapper {
  position: absolute;
  left: 0;
  top: var(--header-height);
  width: 100%;
  height: calc(100vh - var(--header-height));
  padding-bottom: var(--header-height);
  z-index: $z-menu;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s linear;
  background: var(--color-nav-background);
  overflow-y: auto;

  @media screen and (min-width: #{$bp-mobileNav}) {
    position: static;
    height: auto;
    padding-bottom: 0;
    display: block !important;
    opacity: 1;
    visibility: visible;
    overflow: visible;
    --color-nav-background: transparent;
  }

  &.is-open {
    opacity: 1;
    visibility: visible;
    display: block !important;
  }

}



.hero {

  --color-text: var(--color-white, #ffffff);

  &__wrapper {
    position: relative;
    min-height: 100vh;
    width: 100%;
    padding-bottom: calculateRem(100);
    padding-top: 25vh;
    background: var(--color-grey-dark);
    @include frame-padding-left-right;

    display: flex;
    justify-content: center;

    @media screen and (min-width: #{$bp-1024}) {
      align-items: center;
      padding-top: calculateRem(100);
    }

    &.hero__wrapper--no-content {
      .fullwidth-image-with-text-above:before {
        display: none;
      }
    }
  }

  &__content {
    width: 100%;
    position: relative;
    z-index: 1;
    @media screen and (min-width: #{$bp-mobileNav}) {
      padding-right: var(--hamburger-width);
    }

    .arrow-link,
    .hero__image-content + .frame-header,
    .hero__image-content + .hero__text-content,
    .frame-header + .hero__image-content,
    .hero__text-content + .hero__image-content{
      margin-top: 20px;
     @media screen and (min-width: #{$bp-1366}) {
        margin-top: 25px;
      }
      @media screen and (min-width: #{$bp-1920}) {
        margin-top: 40px;
      }
    }

  }
}

// slider overrides
.hero {
  .glide__bullets {
    position: absolute;
    z-index: 10;
    bottom: 0;
    @include frame-padding-left-right;
    @media screen and (min-width: #{$bp-mobileNav}) {
      padding-right: var(--header-logo-widht);
      padding-left: var(--header-logo-widht);
    }
  }

  .glide__bullet {
    background: var(--color-grey-dark);

    &--active {
      background: var(--color-white);
    }
  }
}

// layout variants
.hero--subpage {
  .hero__wrapper {
    align-items: flex-end;
    justify-content: flex-start;
  }

  .hero__content {
   @media screen and (min-width: #{$bp-1366}) {
      width: 80%;
    }

    h1, .hl-1 {
      @include headline('h2');
    }
  }
}

// hero no preview image -> news
.hero--has-no-preview-image {

  .hero__wrapper {
    min-height: auto;
    background: var(--color-page-background);
    padding-top: calc(var(--header-height) * 1.5);
    padding-bottom: calc(var(--header-height) * 0.5);
  }

  .teaser-text {
    color: var(--color-text);
    div, span {
      color: var(--color-text);
    }
  }

  .scroll-down__wrapper {
    display: none;
  }

}

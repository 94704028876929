.nav-back-button {
  display: none;

  color: var(--color-primary);

  padding: 0 15px;
  background: var(--color-sidebar-background);

  width: 100%;
  height: var(--header-height);

  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  transition: opacity 0.15s linear, filter 0.15s linear, background 0.35s ease;
  @include  border-styling('bottom');

  & > svg {
    transform: rotate(-90deg);
  }

  span {
    color: var(--color-primary);
  }

  &.is-visible {
    display: flex;
  }

}

.nav-back-button {
  .breadcrumb__list {
    padding: 0;
    display: flex;
  }
  .breadcrumb__item {
    margin-right: 10px;
  }
  .breadcrumb__icon {
    margin-left: 10px;
    color: var(--color-grey);
  }
}

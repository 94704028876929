@mixin firefox-only {
  @at-root {
    @-moz-document url-prefix() {
      @content;
    }
  }
}

/*--- Only Chrome 28+, Opera ≥ 14 ---*/
@mixin only_chrome() {
  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm) {
    @content;
  }
}

/*--- Only iOS Safari ≥ 9 ---*/
@mixin only_ios() {
  @supports (-webkit-touch-callout: none) and (-webkit-text-size-adjust:none) {
    @content;
  }
}

@mixin only_safari() {
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    @content;
  }
}

/*--- Only Edge ---*/
@mixin only_edge($selector, $map) {
  @supports (-ms-accelerator:true) {
    @content;
  }
}

/*--- Only IE ≤ 11 ---*/
@mixin only_ie() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

// source for below: https://medium.com/@ferie/detect-a-touch-device-with-only-css-9f8e30fa1134

/**
The hover media feature is used to query the user’s ability
to hover over elements on the page with the primary pointing device.
If a device has multiple pointing devices, the hover media feature
must reflect the characteristics of the “primary” pointing device,
as determined by the user agent.
*/
@mixin can_hover {
  @media (hover: hover) {
    @content;
  }
  @include only_ie {
    @content;
  }
}

@mixin can_not-hover() {
  @media (hover: none) {
    @content;
  }
}

/**
The pointer media feature is used to query the presence
and accuracy of a pointing device such as a mouse.
If multiple pointing devices are present, the pointer
media feature must reflect the characteristics
of the “primary” pointing device, as determined
by the user agent.
*/
@mixin no_pointer-device() {
  @media (pointer: none) {
    @content;
  }
}

/* smartphones, touchscreens */
@mixin on_smartphones_and_touchscreens() {
  @media (hover: none) and (pointer: coarse) {
    @content;
  }
}

/* stylus-based screens */
@mixin on_stylus-based-screens() {
  @media (hover: none) and (pointer: fine) {
    @content;
  }
}

/* Nintendo Wii controller, Microsoft Kinect */
@mixin on_nitendo-wii_and_microsoft-kinect() {
  @media (hover: hover) and (pointer: coarse) {
    @content;
  }
}

/* mouse, touch pad */
@mixin on_mouse_or_touch-pad() {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

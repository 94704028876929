.glide {
  height: 100%;
  width: 100%;
}

.glide__bullets {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: $gutter;
  padding-bottom: $gutter;
}

.glide__bullet {
  display: block;
  width: 5px;
  min-width: 5px;
  height: 5px;
  min-height: 5px;
  background: var(--color-text);
  border-radius: 200px;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  &--active {
    background: var(--color-primary);
  }
}

.glide__arrows {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.glide__arrow {
  margin-bottom: calculateRem(5);

  &:last-child {
    margin-bottom: 0;
  }
}

/**
* Headlines
 */

h1, .hl-1 {
  @include headline('h1');
}

h2, .hl-2 {
  @include headline('h2');
}

h3, .hl-3 {
  @include headline('h3');
}

h1, .hl-1, h2, .hl-2, h3, .hl-3 {
  // fw-extra-bold
  i, em {
    font-weight: $font-extra-bold-italic;
  }
}

.text, p {
  padding: 0 0 1em 0;
  margin: 0;
  @include text;

  &:last-child {
    padding-bottom: 0;
  }
}

.text--highlighted {
  // light
  @include fw-bold;
  text-transform: uppercase;
  padding-bottom: 1.5em;
  padding-top: 2em;
  color: var(--color-primary);
}

i, em {
  font-style: italic;
}

b, strong {
  @include fw-bold;
}

p {
  i, em {
    // light
    font-family: $font-light-italic;
    @media screen and (min-width: #{$bp-1920}) {
      // medium
      font-family: $font-medium-italic;
    }
  }

  b, strong {
    // light
    @include fw-medium;
    @media screen and (min-width: #{$bp-1920}) {
      // medium
      @include fw-bold;
    }
  }

}


abbr[title] {
  border-bottom: 1px dotted;
}

dfn {
  font-style: italic;
}

ins {
  background: var(--color-marker);
  color: var(--color-text);
  text-decoration: none;
}

mark {
  background: var(--color-marker);
  color: var(--color-text);
  font-style: italic;
  font-weight: bold;
}

pre, code, kbd, samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

small {
  font-size: 85%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

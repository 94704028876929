@mixin link() {
  cursor: pointer;
  @include fw-bold;
  text-decoration: none;
  text-transform: uppercase;
  outline: none;
  color: var(--color-link);
  @include fs;
  transition: all 0.25s ease;
  background: var(--color-link-background, transparent);

  @include can_hover {
    &:hover {
      outline: 0;
      color: var(--color-link-hover);
      background: var(--color-link-background-hover, transparent);
    }
    &:visited:hover {
      outline: 0;
      color: var(--color-link-hover);
    }
  }

  &:focus {
    outline: 0;
  }

  &:visited {
    color: var(--color-link);
  }

  &:active {
    outline: 0;
  }
}

@mixin text-link {
  cursor: pointer;
  @include fw-bold;
  text-decoration: none;
  text-transform: none;
  outline: none;
  color: var(--color-text);
  @include fs;
  transition: all 0.25s ease;
  background: transparent;

  @include can_hover {
    &:hover {
      outline: 0;
      color: var(--color-link-hover);
      background: transparent;
    }
    &:visited:hover {
      outline: 0;
      color: var(--color-link-hover);
    }
  }

  &:focus {
    outline: 0;
  }

  &:visited {
    color: var(--color-text);
  }

  &:active {
    outline: 0;
  }
}


@mixin download-link() {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  vertical-align: baseline;
  border: none;
  color: var(--color-page-background, #ffffff);

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.35s ease;
    z-index: 5;
    width: 25px;
    height: 25px;
    padding: 2px;
    background: url('/typo3conf/ext/revier_master/Resources/Public/Static/Icons/Frontend/download-arrow-orange.svg') no-repeat;
   @media screen and (min-width: #{$bp-1366}) {
      width: 35px;
      height: 35px;
    }
  }

  @include can_hover {
    &:hover {
      color: var(--color-page-background, #ffffff);
      &:before {
        transform: translate(-50%, -45%);
      }
    }
  }
}

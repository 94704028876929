.page-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: $z-menu;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  @include border-styling('bottom');
  height: var(--header-height);

  @media screen and (min-width: #{$bp-mobileNav}) {
    width: 100%;
  }

}

.page-header:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.35s ease;
}

.page-header:after {
  display: none;
  content: '';
  position: absolute;
  left: 0;
  top: calc(var(--header-height) - 1px);
  width: 100%;
  height: var(--border-height);
  background: var(--color-primary);
  z-index: $z-above-menu;

  pointer-events: none;
}

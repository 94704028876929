.logo {
  position: relative;
  z-index: $z-5;

  height: 100%;
  width: var(--header-logo-widht);
  background: var(--color-sidebar-background);
  transition: opacity 0.35s ease;

  .icon--logo-mobile {
    display: block;
    @media screen and (min-width: #{$bp-mobileNav}) {
      display: none;
    }
  }

  .icon--logo {
    display: none;
    @media screen and (min-width: #{$bp-mobileNav}) {
      display: block;
    }
  }
}


.logo__link {
  display: block;
  height: 100%;
}

.logo__link-content {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 $gutter;
  @include border-styling('right');

  @media screen and (min-width: #{$bp-mobileNav}) {
    justify-content: center;
    padding: 0 41px;
  }
}

body.menu--has-hover {
  .page-header {
    &:before {
      @media screen and (min-width: #{$bp-mobileNav}) {
        width: 100%;
        height: 100vh;
        z-index: $z-0;
        background: var(--color-white);
        opacity: 1;
      }
    }

    &:after {
      @media screen and (min-width: #{$bp-mobileNav}) {
        display: block;
      }
    }
  }
}

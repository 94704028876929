.scroll-down {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding: calculateRem(10);
  cursor: pointer;

  &__wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 5;
   @media screen and (min-width: #{$bp-1366}) {
      bottom: 5px;
    }
  }

  &__icon {
    display: block;
    transform: translateY(-5px);
    transition: all 0.35s ease;
    @include can_not-hover {
      transform: none;
    }
  }

  &__text {
    opacity: 0;
    transition: all 0.35s ease;
    @include can_not-hover {
      opacity: 1;
    }
  }

  .icon--chevron-down {
    width: 35px;
    height: 35px;
  }

  @include can_hover {
    &:hover {
      .scroll-down__icon {
        transform: translateY(0);
      }
      .scroll-down__text {
        opacity: 1;
      }
    }
  }
}

:root {
  // =========
  /// Colors
  // =========
  --color-black: #000000;
  --color-white: #ffffff;
  --color-grey: #9d9d9d;
  --color-orange: #FF5041;
  --color-orange-transparent: rgba(255, 80, 65, 0.5);
  --color-blue: #5CD1DE;
  --color-blue-transparent: rgba(92, 209, 222, 0.5);
  --color-green: #21a84a;
  --color-green-transparent: rgba(33, 168, 74, 0.5);
  --color-grey-dark: #1C1C1C;
  --color-grey-light: #cccccc;

  --color-primary: var(--color-orange);
  --color-page-background: var(--color-white);
  --color-header-background: var(--color-white);
  --color-text: var(--color-black);
  --color-sitemap-text: var(--color-black);
  --color-headline: var(--color-orange);

  --color-link: var(--color-orange);
  --color-link-hover: var(--color-orange);

  --color-button-text: var(--color-primary);
  --color-button-text-hover: var(--color-white);
  --color-button-background: transparent;
  --color-button-background-hover: var(--color-primary);

  --color-form-input-background: var(--color-white);
  --color-form-placeholder: var(--color-grey-dark);

  --color-nav-background: var(--color-white);
  --color-sub-nav-link: var(--color-black);
  --color-sub-nav-link-hover: var(--color-link);
  --color-sidebar-background: transparent;

  --color-success: #34860e;
  --color-error: #f30606;

  --color-marker: #f5be08;

  --image-overlay-color: rgba(0, 0, 0, 0.6);

  --color-frame-background: transparent;
  --color-image-link-hover: var(--color-orange-transparent);

  // =========
  // Font
  // =========
  --letter-spacint-headline: 8px;

  // =========
  // Widths
  // =========

  --hamburger-width: 20vw;
  --header-logo-widht: calc(#{$space} * 2);
  --page-contact-widht: var(--hamburger-width, 55px);

  --content-space-left: #{$space};
  --content-space-right: #{$space};
  --content-widht: calc(100% - var(--content-space-left) - var(--content-space-right));

  // =========
  // Heights
  // =========
  --header-height: 62px;
  --hamburger-line-height: 0.5px;
  --page-contact-height: var(--header-height, 62px);
  --overline-line-height: 0.5px;
  --input-height: 30px;

  // =========
  // Border
  // =========
  --border-height: 0.2px;
  --border-color: var(--color-orange);
  --border-style:  solid;
  --image-border: var(--border-height) solid var(--color-grey-light);

  // =========
  // Spacing
  // =========
  --frame-space-before: calc(var(--header-height) * 1.5);
  --frame-space-after: calc(var(--header-height) * 1.5);
}
@media screen and (min-width: #{$bp-mobileNav}) {
  :root {
    // =========
    // Widths
    // =========
    --hamburger-width: 10vw;
    --header-logo-widht: 14.28vw;
    --content-widht: calc(100% - var(--hamburger-width) - var(--header-logo-widht));
    --page-contact-widht: var(--hamburger-width, 7.14vw);

    --content-space-left: 14.28vw;
    --content-space-right: 7.14vw;

    // =========
    // Heights
    // =========
    --header-height: 7.14vw;
    --page-contact-height: var(--header-height, 7.14vw);
  }
}
@media screen and (min-width: #{$bp-1366}) {
  :root {
    --input-height: 48.61px;

    // =========
    // Widths
    // =========
    --hamburger-width: 7.14vw;

    // =========
    // Spacing
    // =========
    --frame-space-before: calc(var(--header-height) * 2);
    --frame-space-after: calc(var(--header-height) * 2);
  }
}

@include firefox-only {
  :root {
    --border-height: 1px;
    --hamburger-line-height: 1px;
    --overline-line-height: 1px;
  }
}

@media screen and (min-width: #{$bp-1366}) {
  :root {
    --header-logo-widht: 192px;
    // =========
    // Font
    // =========
    --letter-spacint-headline: 6px;
  }
}

@media screen and (min-width: #{$bp-1920}) {
  :root {
    // =========
    // Heights
    // =========
    --header-height: 118px;
    --page-contact-height: var(--header-height, 118px);
    // =========
    // Font
    // =========
    --letter-spacint-headline: 6px;
  }
}

@mixin button($type:'default') {

  display: inline-block;
  cursor: pointer;
  @include text-size();
  transition: 200ms;
  padding: 0.5em 1.5em;

  @include border-styling();
  font-weight: 700;
  color: var(--color-button-text);
  background: var(--color-button-background);
  @include can_hover {
    &:hover {
      color: var(--color-button-text-hover);
      background: var(--color-button-background-hover);
    }
  }

}


@mixin button-hover($type : 'default') {
  position: relative;
  cursor: pointer;
  * {
    position: relative;
    z-index: 1;
    transition: color 0.35s ease;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    transition: width 0.35s ease;
  }
  @include can_hover {
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
  &.is-active {
    &:after {
      width: 100%;
    }
  }

  @if $type == 'default' {
    &:after {
      background: var(--color-primary);
    }
    @include can_hover {
      &:hover {
        color: var(--color-white);

        * {
          color: var(--color-white);
        }
      }
    }
    &.is-active {
      color: var(--color-white);

      * {
        color: var(--color-white);
      }
    }
  } @else if $type == 'inverted' {
    &:after {
      background: var(--color-white);
    }
    @include can_hover {
      &:hover {
        color: var(--color-primary);

        * {
          color: var(--color-primary);
        }
      }
    }
    &.is-active {
      color: var(--color-primary);

      * {
        color: var(--color-primary);
      }
    }
  }
}


.nav-list.nav-list--header .language-changer {
  border: none;
  .language-changer__list {
    top: -100%;
  }
}

.language-changer {

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  @include border-styling('top');
  @include border-styling('bottom');

  @media screen and (min-width: #{$bp-mobileNav}) {
    border-bottom: none;
  }


  &__trigger {
    position: relative;
    cursor: pointer;
    height: var(--header-height);

    display: flex;

    align-items: center;
    justify-content: center;
    gap: 10px;

    color: var(--color-primary);
    transition: all 0.35s ease-in-out;
    z-index: 2;

    padding: 44px 50px;

    @media screen and (min-width: #{$bp-mobileNav}) {
      width: 100%;
      flex-direction: column;
      gap: 0;
      padding: 0;
    }

    @media screen and (min-width: #{$bp-mobileNav}) {
      order: 2;
    }

    svg {
      transform: rotate(180deg);
      @media screen and (min-width: #{$bp-mobileNav}) {
        transform: rotate(0deg);
      }
    }
  }

  &__trigger-text {
    position: relative;
    @include fw-bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.35s ease-in-out;
    @media screen and (min-width: #{$bp-mobileNav}) {
      @include text-size('small');
      order: 1;
      margin-top: 10px;
    }
  }

  &__list {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;

    position: absolute;
    width: 100%;
    left: -1px;
    top: -100%;

    background: var(--color-primary);
    @include border-styling();
    border-top: none;

    transition: all 0.35s ease-in-out;
    transform: translateY(-5px);

    @media screen and (min-width: #{$bp-mobileNav}) {
      bottom: var(--header-height);
      background: var(--color-sidebar-background);
      @include border-styling();
      border-right: none;
      transform: translateY(5px);
      top: auto;
      left: 0;
    }
  }

  &__item {
    &.is-active-language {
      .language-changer__link {
        color: var(--color-primary);
        background: var(--color-white);
        @include can_hover {
          &:hover {
            color: var(--color-primary);
            background: var(--color-white);
          }
        }
      }
    }
  }

  &__link {
    display: block;
    padding: calc(#{$gutter} / 2) $gutter;
    text-align: center;
    color: var(--color-white);
    background: var(--color-primary);
    @include fw-medium;
    @include text-size('small');

    @media screen and (min-width: #{$bp-1366}) {
      padding: calc(#{$gutter} / 1.5) $gutter;;
    }

    &:active, &:visited {
      color: var(--color-white);
      @include can_hover {
        &:hover {
          color: var(--color-primary);
        }
      }
    }

    @include can_hover {
      &:hover {
        background: var(--color-white);
        color: var(--color-primary);

        .language-changer__trigger-text {
          color: var(--color-white);
        }
      }
    }
  }
}

// hover
.language-changer:hover {

  @include can_hover {
    .language-changer__trigger-text {
      color: var(--color-white);
    }

    .language-changer__trigger {
      background: var(--color-primary);

      svg {
        color: var(--color-white);
        transform: rotate(0deg);
        @media screen and (min-width: #{$bp-mobileNav}) {
          transform: rotate(180deg);
        }
      }
    }

    .language-changer__list {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
      transform: translateY(0);
    }
  }
}


.language-changer__trigger.is-active {
  background: var(--color-primary);

  .language-changer__trigger-text {
    color: var(--color-white);
  }

  svg {
    color: var(--color-white);
    transform: rotate(0deg);
    @media screen and (min-width: #{$bp-mobileNav}) {
      transform: rotate(180deg);
    }
  }
}

.language-changer__list.is-active {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  transform: translateY(0);
}

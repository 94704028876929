.nav-list--side {
  flex-direction: column;
  overflow: visible;

  .nav-list__item {
    @media screen and (min-width: #{$bp-mobileNav}) {
      width: 100%;
      height: var(--header-height);
      @include border-styling('top');
    }

    .nav-list__title, .nav-list__link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    & > a.nav-list__link span,
    & > .nav-list__title-wrapper a.nav-list__title span {
      text-align: center;
      @media screen and (min-width: #{$bp-mobileNav}) {
        @include text-size('small');
      }
    }
  }
}

.nav-list.nav-list--header {
  @media screen and (max-width: #{$bp-mobileNav}) {
    transition: height 0.35s ease-in-out;
  }
}

.nav-list {
  position: relative;
  z-index: 1;
  overflow: hidden;

  @media screen and (min-width: #{$bp-mobileNav}) {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: visible;
  }

  &__item {

    @include border-styling('bottom');
    @media screen and (min-width: #{$bp-mobileNav}) {
      border: none;
    }

    &.is-active {

      & > .sub-nav-list {
        @media screen and (min-width: #{$bp-mobileNav}) {
          display: block !important;
          opacity: 1;
          pointer-events: auto;
          visibility: visible;
        }
      }

      & > a.nav-list__link,
      & > .nav-list__title-wrapper {
        color: var(--color-white);

        &:after {
          width: 100%;
        }

        .nav-list__trigger, .nav-list__title {
          color: var(--color-white);

          svg {
            stroke: var(--color-white);
          }
        }
      }
    }

    &.is-current {
      & > a.nav-list__link span,
      & > .nav-list__title-wrapper a.nav-list__title span {
        border-bottom: 2px solid var(--color-link);
      }
    }
  }

  &__title-wrapper, &__link {
    @include button-hover();
    display: block;
    background: var(--color-nav-background);
    @media screen and (min-width: #{$bp-mobileNav}) {
      height: var(--header-height);
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    display: block;
    width: 100%;
    padding: 35px 50px;
    @media screen and (min-width: #{$bp-mobileNav}) {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 35px 11px;
    }
    @media screen and (min-width: #{$bp-1280}) {
      padding: 35px 29px;
    }
    @media screen and (min-width: #{$bp-1366}) {
      padding: 35px 33px;
    }
    @media screen and (min-width: #{$bp-1420}) {
      padding: 35px 37px;
    }
    @media screen and (min-width: #{$bp-1542}) {
      padding: 35px 50px;
    }
  }

  &__trigger {
    cursor: pointer;
    color: var(--color-link);
    padding: 35px 50px;

    @media screen and (min-width: #{$bp-mobileNav}) {
      padding-left: 0;
      padding-right: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: default;
      pointer-events: none;
    }


    svg {
      transform: rotate(90deg);
      stroke: var(--color-link);
    }

    &.is-active {
      svg {
        @media screen and (min-width: #{$bp-mobileNav}) {
          transform: rotate(-180deg);
        }
      }
    }

  }

  &__link {
    padding: 35px 50px;
  }


}

// 1st level
.nav-list > .nav-list__item > .nav-list__title-wrapper > .nav-list__trigger {
  @media screen and (min-width: #{$bp-mobileNav}) {
    display: none;
  }
}

.nav-list > .nav-list__item > .nav-list__link {
  @media screen and (min-width: #{$bp-mobileNav}) {
    display: flex;
    align-items: center;
  }
}

// base for 2nd, 3rd and 4th level
.sub-nav-list {
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: translateX(100%);
  transition: transform 0.25s linear;
  background: var(--color-nav-background);

  @media screen and (min-width: #{$bp-mobileNav}) {
    --color-nav-background: #ffffff;
    background: transparent;
    transform: none;

    position: absolute;
    height: 100%;
    width: calc(100% / 3);
    max-height: none;

    overflow: visible;
  }

  &.is-visible {
    display: block !important;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }

  &.is-active {
    transform: translateX(0);
  }

  &:has(.has-children) {
    // if one of the children has children, align all links and titles to the left
    & > .nav-list__item > .nav-list__title-wrapper .nav-list__title,
    & > .nav-list__item > .nav-list__link {
      @media screen and (min-width: #{$bp-mobileNav}) {
        justify-content: flex-start;
      }
    }
  }

  .nav-list__item {
    @media screen and (min-width: #{$bp-mobileNav}) {
      width: 100%;
      @include border-styling;
      border-top: none;
      background: var(--color-nav-background);
    }
  }

  & > .nav-list__item {

    &.has-children {
      & > .nav-list__title-wrapper .nav-list__title {
        @media screen and (min-width: #{$bp-mobileNav}) {
          justify-content: flex-start;
        }
      }
    }

    & > .nav-list__title-wrapper,
    & > .nav-list__link {
      @media screen and (min-width: #{$bp-mobileNav}) {
        height: var(--header-height);
      }
      @media screen and (min-width: #{$bp-1420}) {
        height: calc(var(--header-height) * 1.2);
      }
    }

    & > .nav-list__link {
      @media screen and (min-width: #{$bp-mobileNav}) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .nav-list__trigger {
    &.is-active {
      svg {
        transform: rotate(90deg);
      }
    }
  }

}

// 2nd level
.sub-nav-list {
  z-index: 2;
}

.nav-list > .nav-list__item > .sub-nav-list {
  @media screen and (min-width: #{$bp-mobileNav}) {
    position: absolute;
    top: var(--header-height);
    left: 0;
    width: 100%;
    max-width: calc(100vw - var(--header-logo-widht) - var(--hamburger-width));
    transform: none;

    height: calc(100vh - var(--header-height) - 10px);
    overflow-y: auto;

    pointer-events: none;

  }

  & > .nav-list__item {
    pointer-events: auto;
    @media screen and (min-width: #{$bp-mobileNav}) {
      width: calc(100% / 3);
    }
  }
}

// 3rd level
.sub-nav-list .sub-nav-list {
  z-index: 3;
  @media screen and (min-width: #{$bp-mobileNav}) {
    top: 0;
    left: calc(100% / 3);
    width: calc(100% / 3);
  }
}

// 4th level
.sub-nav-list .sub-nav-list .sub-nav-list {
  z-index: 4;

  @media screen and (min-width: #{$bp-mobileNav}) {
    top: 0;
    left: 100%;
    width: 100%;
  }

  & > .nav-list__item {
    & > .nav-list__title-wrapper,
    & > .nav-list__link {
      @media screen and (min-width: #{$bp-mobileNav}) {
        height: calc(var(--header-height) / 2);
      }
      @media screen and (min-width: #{$bp-1420}) {
        height: calc((var(--header-height) * 1.2) / 2);
      }
    }
  }
}

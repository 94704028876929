body.device--is-touch-device {

  // 1st level: show trigger for opening subnav
  .nav-list > .nav-list__item > .nav-list__title-wrapper {
    .nav-list__title {
      padding-right: 0;
    }
    & > .nav-list__trigger svg {
      @media screen and (min-width: #{$bp-mobileNav}) {
        transform: rotate(0deg);
      }
    }
  }

  .nav-list > .nav-list__item.is-active > .nav-list__title-wrapper > .nav-list__trigger {
    svg {
      @media screen and (min-width: #{$bp-mobileNav}) {
        transform: rotate(180deg);
      }
    }
  }

  .nav-list__item > .nav-list__title-wrapper {

    & > .nav-list__trigger {
      @media screen and (min-width: #{$bp-mobileNav}) {
        display: flex;
        padding-left: 35px;
        padding-right: 35px;
        pointer-events: auto;
      }
    }
  }


}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

// Function for converting a px based font-size to rem.
@function calculateRem($size) {
  $remSize: $size / $font-base;
  //Default font size on html element is 100%, equivalent to 16px;
  @return #{$remSize}rem;
}

@mixin font-size($sizeValue) {
  font-size: ($sizeValue) + px;
  font-size: ($sizeValue / $font-base) + rem;
}

@mixin fs() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  font-smooth: always;
}

@mixin fw-light() {
  font-weight: 300;
  font-family: $font-light;
}

@mixin fw-medium() {
  font-weight: 500;
  font-family: $font-medium;
}

@mixin fw-bold() {
  font-weight: 700;
  font-family: $font-bold;
}

@mixin fw-extra-bold() {
  font-weight: 800;
  font-family: $font-extra-bold;
}

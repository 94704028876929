.fullwidth-image-with-text-above {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .image-embed-item {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: 100vw;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: var( --image-overlay-color);
  }
}

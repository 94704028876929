.page-content__aside .scroll-top__wrapper {
  display: none;
  margin-bottom: auto;
  margin-top: var(--header-height);
  @media screen and (min-width: #{$bp-mobileNav}) {
    display: block;
  }
}

.scroll-top {

  display: none;

  position: fixed;
  right: 0;
  bottom: 63px;
  padding: 30px 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (min-width: #{$bp-mobileNav}) {
    position: fixed;
    width: var(--hamburger-width);
    height: var(--header-height);
    right: 0;
    top: var(--header-height);
    z-index: 999;
    @include border-styling('bottom');
  }

  @media screen and (min-width: #{$bp-1670}) {
    height: 96px;
  }

  &.scroll-top--is-shown {
    display: flex;
  }

  svg {
    stroke-width: 3px;
    @media screen and (min-width: #{$bp-mobileNav}) {
      stroke-width: 1px;
    }
  }

  @include can_hover {
    &:hover {
      .icon--scroll-top-btn {
        transform: translateY(-6px);
      }
    }
  }

}

* {
  box-sizing: border-box;
}

body {
  position: relative;

  @include text-size;
  color: var(--color-text);
  @include font-text;

  text-align: left;
  padding: 0;
  background: var(--color-page-background);
}

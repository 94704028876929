@mixin text-size($size: 'normal') {

  @if $size == 'normal' {
    font-size: 14px;
    font-size: calculateRem(14);
    line-height: 18px;
    line-height: calculateRem(18);
    @media screen and (min-width: #{$bp-1024}) {
      font-size: 16px;
      font-size: calculateRem(16);
      line-height: 20px;
      line-height: calculateRem(20);
    }
   @media screen and (min-width: #{$bp-1366}) {
      font-size: 18px;
      font-size: calculateRem(18);
      line-height: 22px;
      line-height: calculateRem(22);
    }
    //@media screen and (min-width: #{$bp-1920}) {
    //  font-size: 16px;
    //  font-size: calculateRem(16);
    //  line-height: 20px;
    //  line-height: calculateRem(20);
    //}
  }

  @if $size == 'small' {
    font-size: 13px;
    font-size: calculateRem(13);
    line-height: 16px;
    line-height: calculateRem(16);
   @media screen and (min-width: #{$bp-1366}) {
      font-size: 12px;
      font-size: calculateRem(12);
      line-height: 15px;
      line-height: calculateRem(15);
    }
  }

  @if $size == 'extra-small' {
    font-size: 10px;
    font-size: calculateRem(10);
    line-height: 13px;
    line-height: calculateRem(13);
  }

  @if $size == 'medium' {
    font-size: 14px;
    font-size: calculateRem(14);
    line-height: 16px;
    line-height: calculateRem(16);
   @media screen and (min-width: #{$bp-1366}) {
      font-size: 16px;
      font-size: calculateRem(16);
      line-height: 21px;
      line-height: calculateRem(21);
    }
  }


}

@mixin font-text() {
  @include fw-light;
  @media screen and (min-width: #{$bp-1920}) {
    @include fw-medium;
  }
}

@mixin text() {
  @include text-size;
  color: var(--color-text);
  @include font-text;
  @include fs;
  padding: 0 0 1em 0;
  margin: 0;
}



/**
base like primary-color, text, headlines, links, buttons, borders
 */

// blue
.color-scheme--blue,
.color-scheme--5,
.frame-color-scheme--5,
.header-background-color-scheme--5 .page-header,
.header-background-color-scheme--5 .scroll-top,
.header-background-color-scheme--5 .page-content__aside,
body.color-scheme--:has(.news-single.frame-color-scheme--5),
body.color-scheme--4:has(.news-single.frame-color-scheme--5){
  --color-primary: var(--color-blue);

  --color-text: var(--color-white);
  --color-headline: var(--color-blue);

  --color-link: var(--color-blue);
  --color-link-hover: var(--color-blue);
  --color-image-link-hover: var(--color-blue-transparent);

  --color-button-text: var(--color-blue);
  --color-button-text-hover: var(--color-white);
  --color-button-background: transparent;
  --color-button-background-hover: var(--color-blue);

  --color-sidebar-icon: var(--color-blue);
  --color-sidebar-text: var(--color-blue);

  --border-color: var(--color-blue);

  --image-overlay-color: rgba(0, 0, 0, 0.6);

  .hero--has-no-preview-image {
    --color-text: var(--color-white);
  }
}

// orange
.color-scheme--orange,
.color-scheme--4,
.frame-color-scheme--4,
.header-background-color-scheme--4 .page-header,
.header-background-color-scheme--4 .scroll-top,
.header-background-color-scheme--4 .page-content__aside {
  --color-primary: var(--color-orange);

  --color-text: var(--color-black);
  --color-headline: var(--color-orange);

  --color-link: var(--color-orange);
  --color-link-hover: var(--color-orange);
  --color-image-link-hover: var(--color-orange-transparent);

  --color-button-text: var(--color-orange);
  --color-button-text-hover: var(--color-white);
  --color-button-background: transparent;
  --color-button-background-hover: var(--color-orange);

  --color-sidebar-icon: var(--color-orange);
  --color-sidebar-text: var(--color-orange);

  --border-color: var(--color-orange);

  --image-overlay-color: rgba(0, 0, 0, 0.6);

  .hero--has-no-preview-image {
    --color-text: var(--color-black);
  }
}
// green
.color-scheme--6,
.frame-color-scheme--6,
.header-background-color-scheme--6 .page-header,
.header-background-color-scheme--6 .scroll-top,
.header-background-color-scheme--6 .page-content__aside {
  --color-primary: var(--color-green);

  --color-text: var(--color-black);
  --color-headline: var(--color-green);

  --color-link: var(--color-green);
  --color-link-hover: var(--color-green);
  --color-image-link-hover: var(--color-green-transparent);

  --color-button-text: var(--color-green);
  --color-button-text-hover: var(--color-black);
  --color-button-background: transparent;
  --color-button-background-hover: var(--color-green);

  --color-sidebar-icon: var(--color-green);
  --color-sidebar-text: var(--color-green);

  --border-color: var(--color-green);

  --image-overlay-color: rgba(0, 0, 0, 0.6);
}

/**
specific background colors or text colors
 */
// blue
.color-scheme--blue,
.color-scheme--5,
body.color-scheme--:has(.news-single.frame-color-scheme--5),
body.color-scheme--4:has(.news-single.frame-color-scheme--5){
  --color-page-background: var(--color-grey-dark);
  --color-header-background: var(--color-grey-dark);
  --color-sidebar-background: transparent;
}

.frame-color-scheme--5 {
  --color-frame-background: var(--color-grey-dark);

  .frame {
    --color-frame-background: transparent;
  }
}
// orange
.color-scheme--orange,
.color-scheme--4 {
  --color-page-background: var(--color-white);
  --color-header-background: var(--color-white);
  --color-sidebar-background: transparent;
}

.frame-color-scheme--4 {
  --color-frame-background: var(--color-white);

  .frame {
    --color-frame-background: transparent;
  }
}

// green
.color-scheme--6 {
  --color-page-background: var(--color-white);
  --color-header-background: var(--color-white);
  --color-sidebar-background: transparent;
}

.frame-color-scheme--6 {
  --color-frame-background: var(--color-white);

  .frame {
    --color-frame-background: transparent;
  }
}

.color-scheme--default {
  --color-sidebar-background: transparent;
}

/**
header
 */
// blue
body.header-background-color-scheme--5,
body.color-scheme--:has(.news-single.frame-color-scheme--5),
body.color-scheme--4:has(.news-single.frame-color-scheme--5){
  .page-header {
    --color-header-background: var(--color-grey-dark);
  }
}
// orange
body.header-background-color-scheme--4 {
  .page-header {
    --color-page-background: var(--color-white);
  }
}
// green
body.header-background-color-scheme--6 {
  .page-header {
    --color-page-background: var(--color-white);
  }
}

/**
body
 */
//blue
body.color-scheme--blue,
body.color-scheme--:has(.news-single.frame-color-scheme--5),
body.color-scheme--4:has(.news-single.frame-color-scheme--5){
  --color-sitemap-text: var(--color-white);
}
// orange
body.color-scheme--orange,
body.color-scheme--,
body.color-scheme--4 {
  --color-sitemap-text: var(--color-black);
}
//green
body.color-scheme--6 {
  --color-sitemap-text: var(--color-black);
}

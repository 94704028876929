.page-content__aside > div.header-search {
  @media screen and (min-width: #{$bp-mobileNav}) {
    height: var(--header-height);
  }
}

body.menu-is-open {
  .page-content__aside > div.header-search .header-search__trigger {
    @include border-styling('bottom');
  }
}

.header-search {
  z-index: 991;
  width: var(--hamburger-width);
  transition: width 0.35s ease;
  height: 100%;
  margin-left: auto;
  @include border-styling('left');


  &__trigger {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);
    background: var(--color-sidebar-background);
    transition: all 0.25s ease;
  }

  &__form-wrapper {
    position: absolute;
    left: 0;
    width: 100vw;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;

    height: var(--header-height);

    padding: 0;

    transition: all 0.35s ease-in-out;
    transform: translateY(-5px);


    @media screen and (min-width: #{$bp-mobileNav}) {
      position: relative;
      top: -1px;
      width: calc((100vw - var(--hamburger-width) - var(--header-logo-widht)) - 15px);
    }

    @media screen and (min-width: #{$bp-mobileNav}) {
      opacity: 0;
      transition: all 0.35s ease;
      left: auto;
      right: calc(var(--hamburger-width) * 5 );
      width: calc(var(--hamburger-width) * 5 );
      top: calc(-1 * var(--header-height));
      transform: translateX(10px);
    }
  }

  &__form {
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: #{$bp-mobileNav}) {
      height: 100%;
    }

    input {
      margin-bottom: 0;
      @media screen and (min-width: #{$bp-mobileNav}) {
        border-top: none;
      }
    }

    input[type=text] {
      background: var(--color-white);
      color: var(--color-primary);
      @media screen and (min-width: #{$bp-mobileNav}) {
        border-radius: 25px;
      }
    }

    input[type="submit"] {
      @include link;
      @include text-size('small');
      @include fw-medium;
      --color-link: var(--color-white);
      --color-link-hover: var(--color-primary);
      --color-link-background: var(--color-primary);
      --color-link-background-hover: var(--color-white);
      flex-basis: calc(100% / 3);
      @include border-styling();
      @include can_hover {
        &:hover {
          border-color: var(--color-primary);
        }
      }
      @media screen and (min-width: #{$bp-mobileNav}) {
        flex-basis: var(--hamburger-width);
      }

    }

  }

  &__searchword-wrapper {
    flex-basis: calc(100% / 3 * 2);
    @media screen and (min-width: #{$bp-mobileNav}) {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-primary);
      flex-basis: calc(100% - var(--hamburger-width));
      padding: 10px;
    }
  }

}

// hover, is-open
.header-search:hover {
  @include can_hover {
    .header-search__trigger {
      background: var(--color-primary);
      color: var(--color-white)
    }
    .header-search__form-wrapper {
      opacity: 1 !important;
      pointer-events: auto;
      visibility: visible;
      transform: translateY(0);
      @media screen and (min-width: #{$bp-mobileNav}) {
        transform: translateX(0);
      }
    }
  }
}
.header-search.is-open {
  .header-search__trigger {
    background: var(--color-primary);
    color: var(--color-white)
  }

  .header-search__form-wrapper {
    opacity: 1 !important;
    pointer-events: auto;
    visibility: visible;
    transform: translateY(0);
    @media screen and (min-width: #{$bp-mobileNav}) {
      transform: translateX(0);
    }
  }
}

.header-search.is-closing {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: translateY(-5px);
}

body.menu-is-open {
  overflow: hidden;

  .page-header {
    background: var(--color-nav-background);
  }

  .logo {
    display: none;
  }

  .header-navigation__wrapper {
    opacity: 1;
    visibility: visible;
    display: block !important;
  }

}



.page-contact {

  display: block;


  @media screen and (min-width: #{$bp-mobileNav}) {
    @include border-styling('top');
    width: 100%;
    height: var(--page-contact-height);
  }

  &__icon {
    cursor: pointer;
    height: var(--page-contact-height);
    padding: 44px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: #{$bp-mobileNav}) {
      width: 100%;
      padding: 0 $gutter;
    }

    svg {
      width: 24px;
      height: auto;
      color: var(--color-primary);
      @media screen and (min-width: #{$bp-mobileNav}) {
        width: 38px;
      }
    }
  }

}

// hover
.page-contact:hover {
  @include can_hover {
    .page-contact__icon {
      background: var(--color-primary);
      color: var(--color-white);

      svg {
        color: var(--color-white);
      }
    }
  }
}

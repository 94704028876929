@mixin border-styling($direction:'all',$important:false) {

  @if $important == false {
    @if $direction == 'all' {
      border-width: var(--border-height);
      border-style: var(--border-style);
      border-color: var(--border-color);
    } @else {
      border-#{$direction}-width: var(--border-height);
      border-#{$direction}-style: var(--border-style);
      border-#{$direction}-color: var(--border-color);
    }
  } @else {
    @if $direction == 'all' {
      border-width: var(--border-height) !important;
      border-style: var(--border-style) !important;
      border-color: var(--border-color) !important;
    } @else {
      border-#{$direction}-width: var(--border-height) !important;
      border-#{$direction}-style: var(--border-style) !important;
      border-#{$direction}-color: var(--border-color) !important;
    }
  }


}

@mixin border() {
  position: relative;

  &:before, &:after {
    display: none;
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: var(--border-height);
    background: var(--color-primary);
    z-index: 1;

    @media screen and (min-width: #{$bp-gridColumnChange}) {
      display: block;
    }

    @media screen and (min-width: #{$bp-mobileNav}) {
      width: calc(100vw - var(--hamburger-width) - 16px);
    }
  }

  // border top
  &:before {
    top: 0;
  }

  // border bottom
  &:after {
    bottom: 0;
  }


}

@mixin frame-padding-left-right($type:'inner') {
  padding-right: var(--content-space-right);
  padding-left: var(--content-space-left);
  @if $type == 'inner' {
    @media screen and (min-width: #{$bp-mobileNav}) {
      --content-space-left: var(--header-logo-widht);
      --content-space-right: calc(var(--hamburger-width) * 2);
    }
  }
  @if $type == 'content' {
    @media screen and (min-width: #{$bp-mobileNav}) {
      --content-space-left: var(--header-logo-widht);
      --content-space-right: var(--hamburger-width);
    }
  }
}

@mixin frame-padding-top-bottom() {
  padding-top: var(--frame-space-before);
  padding-bottom: var(--frame-space-after);
}

// space before
@mixin inner-frame-space-before-none {
  --frame-space-before: 0 !important;
}

@mixin frame-space-before-none {
  --frame-space-before: 0 !important;
}

@mixin frame-space-before-default {
  --frame-space-before: calc(var(--header-height) * 1.5) !important;
  @media screen and (min-width: #{$bp-1366}) {
    --frame-space-before: calc(var(--header-height) * 2) !important;
  }
}

@mixin frame-space-before-small {
  --frame-space-before: calc(var(--header-height) / 2) !important;
}

@mixin frame-space-before-large {
  --frame-space-before: calc(var(--header-height) * 2.2) !important;
}

// space after
@mixin inner-frame-space-after-none {
  --frame-space-after: 0 !important;
}

@mixin frame-space-after-none {
  --frame-space-after: 0 !important;
}

@mixin frame-space-after-default {
  --frame-space-after: calc(var(--header-height) * 1.5) !important;
  @media screen and (min-width: #{$bp-1366}) {
    --frame-space-after: calc(var(--header-height) * 2) !important;
  }
}

@mixin frame-space-after-small {
  --frame-space-after: calc(var(--header-height) / 2) !important;
}

@mixin frame-space-after-large {
  --frame-space-after: calc(var(--header-height) * 2.2) !important;
}
